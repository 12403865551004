import React, { useState } from 'react';
import {
  // Container
  BackgroundEventContainer,
  // Footer Breadcrumb
  FooterBreadcrumbContainer,
  FooterBreadcrumbContent,
  // Main Body
  MainBodyContainer,
  RelatedEventsContent,
  RelatedEventSingular,
  RelatedEventDateTypography,
  RelatedEventImage,
  RelatedEventTitleTypography,
  RelatedEventImageContainer,
} from './styles';
import Breadcrumb from 'components/Breadcrumb';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Header from 'components/Layouts/Generic/Header';
import Body from 'components/Layouts/Generic/Body';
import Tabs from 'components/Tabs';

const tabsArray = [
  {
    title: 'Histoire',
    hash: 'histoire',
  },
  {
    title: "Sélection d'événements",
    hash: 'evenements',
  },
  // {
  //   title: 'Activités Pédagogiques',
  //   hash: 'activites-pedagogiques',
  // },
];

const BackgroundEvent = (props) => {
  const { relatedEvents, event, location } = props;
  const [tab, setTab] = useState(tabsArray[0]);

  return (
    <BackgroundEventContainer>
      <Breadcrumb location={location} isBackgroundEvent={true} event={event} />
      <Header
        title={event.Title}
        overline={`${event.StartDisplayYear}-${event.EndDisplayYear}`}
      />
      <Tabs
        items={tabsArray}
        location={location}
        state={tab}
        setState={setTab}
      />
      <MainBodyContainer>
        <SwitchTransition mode={'out-in'}>
          <CSSTransition
            key={tab?.hash}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            classNames='main-body-animate'
          >
            {tab?.hash === 'histoire' ? (
              <Body html={event?.BAnQDescription} />
            ) : tab?.hash === 'evenements' ? (
              <RelatedEventsContent>
                {relatedEvents?.map((relatedEvent) => {
                  return (
                    <RelatedEventSingular
                      to={`/evenement/${relatedEvent.EventId}`}
                    >
                      {relatedEvent?.ThumbnailPath && (
                        <RelatedEventImageContainer>
                          <RelatedEventImage
                            src={relatedEvent.ThumbnailPath.replace(
                              '220',
                              '600'
                            )}
                          />
                        </RelatedEventImageContainer>
                      )}
                      {relatedEvent?.StartDisplay && (
                        <RelatedEventDateTypography elementTheme='caption'>
                          {relatedEvent.StartDisplay}
                        </RelatedEventDateTypography>
                      )}
                      {relatedEvent?.Title && (
                        <RelatedEventTitleTypography elementTheme='h4'>
                          {relatedEvent.Title}
                        </RelatedEventTitleTypography>
                      )}
                    </RelatedEventSingular>
                  );
                })}
              </RelatedEventsContent>
            ) : tab?.hash === 'activites-pedagogiques' ? (
              <Body html={<p>activites-pedagogiques</p>} />
            ) : (
              <></>
            )}
          </CSSTransition>
        </SwitchTransition>
      </MainBodyContainer>
      <FooterBreadcrumbContainer>
        <FooterBreadcrumbContent>
          <Breadcrumb isBackgroundEvent={true} removeContainer={true} event={event} />
        </FooterBreadcrumbContent>
      </FooterBreadcrumbContainer>
    </BackgroundEventContainer>
  );
};

export default BackgroundEvent;
